#container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#gameArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-shrink: 1;
  flex-grow: 0;
}

#gameContainer {
  width: 768px;
  height: 672px;
}

#middleArea {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

#root, #container {
  height: 100%;
}

#bottomArea {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 100%;
}

#itemStack {
  display: grid;
  position: relative;
  width: 100%;
  height: 168px;
  flex-basis: 168px;
}

#madeBy {
  margin: 0;
  margin-left: 20px;
  display: flex;
  width: 50%;
  height: 58px;
  align-content: center;
  font-family: '8-bit_hudregular';
  font-size: 14px;
  align-items: center;
  align-self: flex-end;
}

#cat {
  width: 45px;
  height: 45px;
  background-image: url(./images/Cat.png);
  background-size: 45px 45px;
  background-repeat: no-repeat;
  background-position: right 25% top 100%;
  max-width: 45px;
  padding-left: 5%;
}

#Grass {
  z-index: 1;
  background-image: url(./images/Grass.png);
}

#Land {
  background-image: url(./images/Land.png);
}

#redBird {
  background-image: url(./images/Red.png);
  background-position: bottom 35% right 25%;
}

#yellowBird {
  background-image: url(./images/Yellow.png);
  background-position: bottom 35% left 25%;
}

#purpleBird {
  background-image: url(./images/Purple.png);
  background-position: top 50% left 40%;
}

#volumeButton {

  width: 45px;
  height: 45px;
  margin: 10px;
}

#volumeHolder {
  width: 100%;
  flex-direction: row-reverse;
}

.imageStackItem {
  height: 100%;
  background-size: 512px 168px;
  grid-row: 1;
  grid-column: 1;
  background-repeat: repeat-x;
  background-position: bottom;
}

.bird {
  background-repeat: no-repeat;
  background-size: 80px 80px;
}

.sideAreas {
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
}

@font-face {
  font-family: '8-bit_hudregular';
  src: url('/public/8-bit-hud-webfont.woff2') format('woff2'),
       url('/public/8-bit-hud-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}